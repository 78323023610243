<template>
  <b-modal
    centered
    ref="take-picture-modal"
    @show="openModal"
    @hidden="dismissModal"
    @hide="onStop"
    @shown="onStart"
    :visible="showTakePictureModal"
    id="take-picture-modal"
    hide-footer
  >
    <template>
      <div slot="modal-header">
        <span class="d-inline-flex modal-header-bar">
          <h5><strong>Scatta Selfie</strong></h5>
          <b-icon
            icon="x"
            shift-v="-4"
            scale="1.7"
            @click="dismissModal()"
            style="cursor: pointer"
          ></b-icon>
        </span>
      </div>
    </template>
    <p class="my-4">
      Scatta un selfie solo quando ti trovi in prossimità del luogo di lavoro. Devono essere ben visibili il volto e lo sfondo.<br>
      In pausa pranzo non è obbligatorio inserire il selfie.
    </p>

    <div v-show="isLoading" class="text-center">
      <b-spinner></b-spinner>
      <div>
        <p>Caricamento</p>
      </div>
    </div>
    <div class="camera-area">
      <vue-web-cam
          v-show="!img"
          ref="webcam"
          :device-id="deviceId"
          width="100%"
          @started="onStarted"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
          style="height: auto;"
      />
        
      <b-img  v-show="img" :src="img" class="img-responsive" style="width: 100%; height: auto;"/>

      <div v-if="!isLoading && !cameraError" class="camera-shoot">
        <div class="icon-container">
          <b-icon
            v-if="!img"
            icon='camera'
            @click="onCapture()"
            style="cursor: pointer;"
          ></b-icon>

          <b-icon
            v-else
            icon='arrow-repeat'
            @click="onDelete()"
            style="cursor: pointer;"
          ></b-icon>
        </div>
      </div>
    </div>

    <b-form-select :plain="true" v-model="camera" v-if="!isLoading && !cameraError" class="mt-2">
      <b-form-select-option :value="null" disabled>-- Scegli fotocamera --</b-form-select-option>
      <b-form-select-option
        v-for="device in devices"
        :key="device.deviceId"
        :value="device.deviceId"
      >{{ device.label }}</b-form-select-option>

    </b-form-select>

    <div v-if="!isLoading && cameraError" class="text-center">
      <div>
        <p>Permessi fotocamera assenti. Chiudere questo pop-up per inviare la timbratura senza selfie.</p>
      </div>
    </div>

    <b-form-group
      label-for="submitTakePicture"
      label-cols-sm="3"
      label-align-sm="right"
      style="text-align: end;"
    >
      <b-button
        type="submit"
        variant="secondary"
        @click="submitTakePicture"
        id="submitTakePicture"
        :disabled="isLoading || !img"
      >
      Accetta foto
      </b-button>
    </b-form-group>
  </b-modal>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
    data() {
        return {
          showTakePictureModal: false, 
          isLoading : true,
          img: null,
          camera: null,
          deviceId: null,
          devices: [],
          cameraError: false
        }
    },
    methods: {
      submitTakePicture: function(){
          this.$emit('pictureAcquired', this.img);
          this.dismissModal();
      },
      dismissModal: function () {
        this.isLoading = true;
        this.img = null;
        this.showTakePictureModal = false;
        this.$bvModal.hide("take-picture-modal");
      },
      openModal: function () {
        this.showTakePictureModal = true;
      },
      onDelete() {
          this.img = null;
      },
      onCapture() {
          this.img = this.$refs.webcam.capture();
      },
      onStarted() {
        this.isLoading = false;
      },
      onStop() {
          this.$refs.webcam.stop();
      },
      onStart() {
          this.$refs.webcam.start();
      },
      onError(error) {
          this.isLoading = false;
          this.cameraError = true;
          this.$emit('disableSelfieMandatory', true);
          console.log("On Error Event", error);
      },
      onCameras(cameras) {
          this.devices = cameras;
          //console.log("On Cameras Event", cameras);
      },
      onCameraChange(deviceId) {
          this.deviceId = deviceId;
          this.camera = deviceId;
          //console.log("On Camera Change Event", deviceId);
      },
    },
    watch: {
        showTakePictureModal: function (val) {
            this.$emit("inputModalEvent", val);
        },
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            const [first] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
            //console.log(tail);
        }
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    created: function () {
        this.$parent.$on("openTakePictureModal", this.openModal);
    },
    components: {
      'vue-web-cam': WebCam
    }
};   
</script>

<style lang="scss"> 

.camera-area{
  position: relative;
}

.camera-shoot {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 50px !important;
  .icon-container {
    background-color: #ffffffbe;
    width: 70px;
    text-align: center;
    border-radius: 100%;
    }
  }

  .web-camera-container {
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
  
  .camera-box {    
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
    }
  }
  }

</style>