<template>
    <div v-if="actualTab === 'log'">
        <Log @tab="switchTab"/>
    </div>

    <div v-else-if="actualTab == 'ask-vacation'">
        <AskVacation @tab="switchTab"/>
    </div>

    <div v-else-if="actualTab == 'ask-overtime'">
        <AskOvertime @tab="switchTab"/>
    </div>

    <div v-else-if="actualTab == 'change-password'">
        <ChangePassword @tab="switchTab"/>
    </div>

    <div v-else-if="actualTab === 'home'" >

        <TakePictureModal
            ref="TakePictureModal"
            @pictureAcquired="fillPictureField"
            @disableSelfieMandatory="disableSelfieMandatory"
        />

        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="logOut"
            id="logout-modal"
            ok-title="Log out"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Sei sicuro di voler effettuare il logout?</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('logout-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>
        </b-modal>

        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>

        <transition name="fade">
            <div class="text-center loader" v-if="gotMessage">
                <b-icon-check-circle 
                    v-if="gotMessage === 'ok'"
                    font-scale=15
                    variant="success"
                >
                </b-icon-check-circle>
                <b-icon-x-circle 
                    font-scale=15
                    variant="danger"
                    v-else
                >
                </b-icon-x-circle>
            </div>
        </transition>

        <EmployeeTopBar 
            title="Botti-Group"
            @tab="switchTab"
            ref="employeeTopBar"
            style="-webkit-transform:translate3d(0,0,0);"
        /> 

        <div :class="isLoading ? 'container disabled' : 'container'" @click="collapseNavbar()">
            <div v-if="!errorMsg">
                <h5 style="float: left;">UTENTE:&nbsp;</h5>
                <h5 style="font-weight: bold; ">{{me.firstName}} {{me.lastName}}</h5>
                <span v-if="lastLogDate">
                    <h4> Ultima timbratura:&nbsp; </h4>
                    <h4 style="font-weight: bold; float: left;"> {{lastLogDate}}&nbsp;</h4>
                    <div class="h2" style="line-height: 1.1em;">
                        <b-icon-box-arrow-in-right v-if="me.lastLogType == 'in'" variant="success"></b-icon-box-arrow-in-right>
                        <b-icon-box-arrow-in-left v-else variant="danger"></b-icon-box-arrow-in-left>
                    </div>
                </span>
                <span v-if="lastLogDate">
                    <h5 v-show="secondsWorked"> Tempo lavorato: {{secondsToHours()}}</h5>
                </span>
            </div>

            <div style="color: var(--danger)" v-else-if="errorMsg && !image">
                <h4 style="font-weight: bold;">ERRORE:</h4>
                <h5 class="text-danger"> {{errorMsg}}</h5>
                <h6 style="font-weight: bold; color: #5B5B5B; text-align: center;"> Riprovare più tardi </h6>
            </div>
            <b-button-group class="buttons" vertical v-if="!image">
                <b-button 
                    block
                    pill
                    variant="secondary"
                    @click="openTakePhotoModal"
                    :disabled="debounceDelayElapsed "
                >
                Scatta selfie
                </b-button>
            </b-button-group>

            <div class="miniature-image" v-else>
                <img v-bind:src="image" />
                <b-button 
                    block
                    pill
                    variant="secondary"
                    @click="openTakePhotoModal"
                    :disabled="debounceDelayElapsed"
                    class="mx-3"
                    style="right: 0;"
                >
                Acquisisci di nuovo
                </b-button>
            </div>
            <b-icon
                icon="arrow-clockwise"
                shift-v="-4"
                scale="1.7"
                @click="updateGPS()"
                id="refresh-gps"
                :style="!image ? '' : 'top: 35px; left: 358px'"
            ></b-icon>
            <div style="position: relative; top: 5px; height: 34vmax; z-index: -1" >
                <l-map
                ref="myMap"
                v-if="!mapIsLoading && gpsError == false"
                    :zoom="15"
                    :center="latLng(gpsData.lat, gpsData.lng)"
                    :options="{zoomSnap: 0.5}"
                    style="height: 100%;"
                >
                    <l-tile-layer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <l-marker :lat-lng="latLng(gpsData.lat, gpsData.lng)">
                    </l-marker>
                </l-map>

                <div class="text-center loader" v-else>
                    <div v-if="gpsError == true">
                        {{gpsErrorMessage}}
                    </div>
                    <b-spinner 
                        v-else-if="!isLoading"
                        style="width: 4rem; height: 4rem;" 
                        variant="secondary"
                    >
                    </b-spinner>
                </div>

            </div>

            <b-button-group class="bottom-button" vertical>
                <b-button 
                block
                pill
                :variant="me.lastLogType == 'in' ? 'danger' : 'success'"
                @click="swipe()"
                :disabled="debounceDelayElapsed"
                >
                {{ me.lastLogType == 'in' ? 'Timbra Uscita' : 'Timbra Entrata' }}
                </b-button>
            </b-button-group>
        </div>
    </div>
    
</template>

<script>
import {getMe, forceLogout, swipeBadge} from "@/services/APIcalls.js";
import EmployeeTopBar from "@/components/EmployeeTopBar/EmployeeTopBar.vue"
import Log from "@/views/Private/Employee/Log.vue"
import ChangePassword from "@/views/Private/Employee/ChangePassword.vue"
import AskVacation from "@/views/Private/Employee/AskVacation.vue";
import AskOvertime from "@/views/Private/Employee/AskOvertime.vue";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import TakePictureModal from "@/components/TakePicture/TakePictureModal";

export default {
    components: {
        EmployeeTopBar,
        Log,
        ChangePassword,
        LMap,
        LTileLayer,
        LMarker,
        AskVacation,
        AskOvertime,
        TakePictureModal
    },
    data() {
        return{
            selfieMandatory: true,
            gpsErrorMessage: '',
            latLng,
            gpsError: false,
            debounceDelayElapsed: false,
            mapIsLoading: true,
            isLoading: true,
            errorMsg: "",
            gotMessage: "",
            actualTab: this.$route.query.page ? this.$route.query.page : 'home',
            gpsData : {},
            me: {},
            image: null,
            secondsWorked: 0,
            interval: null,
        }
    },
    computed: {
        lastLogDate() {
            return this.ISOToHumanReadableFormat(this.me.lastLogDate);
        }
    },
    beforeMount() {
        getMe()
        .then( () => {
            this.isLoading = false;
            this.me = JSON.parse(localStorage.getItem("me") || "{}");
            if (this.me.email == undefined || !this.me) forceLogout();
            this.secondsWorked = this.me?.secondsWorked;
            if(this.me.lastLogType === 'in') {
                this.addOneSecond();
            }
        })
        this.updateGPS();
    },
    methods: {
        addOneSecond(){
            this.interval = window.setInterval(() => {
                this.secondsWorked++;
            },1000);
        },
        stopTimer() {
            if(this.interval !== null) {
                window.clearInterval(this.interval);
            }
        },
        secondsToHours() {
            if(this.secondsWorked) {
                return (new Date(this.secondsWorked * 1000).toISOString().slice(11, 19));
            }
            else return 0;
        },
        disableSelfieMandatory(){
            this.selfieMandatory = false;
        },
        fillPictureField(image){
            if(image) {
                this.image = image;
                this.errorMsg = null;
            }
            else alert("Immagine non acquisita");
        },
        ISOToHumanReadableFormat(ISODate, hideTime = false) {
        if(!ISODate) return '';
        const date = new Date(ISODate);
        const year = `${date.getFullYear()}`;
        const month = `${
            date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1
        }`;
        const day = `${
            date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
        }`;

        if (ISODate.length < 12 || hideTime) return `${day}/${month}/${year}`;
        const hours = `${
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        }`;
        const minutes = `${
            date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }`;
        return `${day}/${month}/${year} - ${hours}:${minutes}`;
        },
        collapseNavbar(){
            this.$refs.employeeTopBar.collapseNavbar();
        },
        openTakePhotoModal() {
            this.$refs.TakePictureModal.openModal();
        },
        updateGPS() {
            this.mapIsLoading = true;
            this.gpsError = false;
            this.$getLocation({maximumAge:0, timeout:5000, enableHighAccuracy : true})
            .then(coordinates => {
                this.gpsData = coordinates;
            })
            .catch( (err) => {
                this.errorCallback_highAccuracy(err);
                this.gpsError = true;
                console.log(err);
                alert("Abilita i permessi per la localizzazione!");
            })
            .finally( () => {
                if( !this.gpsData ) this.gpsError = true;
                this.mapIsLoading = false;
            } )
        },

        errorCallback_highAccuracy(error) {
            if (error.code == error.TIMEOUT)
            {
                this.$getLocation({maximumAge:0, timeout:5000, enableHighAccuracy : false})
                .then((coordinates) => {
                    this.gpsData = coordinates;
                    this.gpsError = false;
                    this.gpsErrorMessage = "";
                    return;
                })
                .catch((err) => {
                    this.errorCallback_lowAccuracy(err);
                })
                return;
            }
            this.gpsError = true;
            
            this.gpsErrorMessage = "Non è possibile geolocalizzare il tuo dispositivo (HIGH). Errore: ";
            if (error.code == 1)
                this.gpsErrorMessage += "PERMESSO_NEGATO";
            else if (error.code == 2)
                this.gpsErrorMessage += "POSIZIONE_NON _DISPONIBILE";
            this.gpsErrorMessage += " "+error;
            
        },

        errorCallback_lowAccuracy(error) {
            this.gpsError = true;
            
            this.gpsErrorMessage =  "Non è possibile geolocalizzare il tuo dispositivo (LOW). Errore: ";
            if (error.code == 1)
                this.gpsErrorMessage += "PERMESSO_NEGATO";
            else if (error.code == 2)
                this.gpsErrorMessage += "POSIZIONE_NON_DISPONIBILE";
            else if (error.code == 3)
                this.gpsErrorMessage += "TIMEOUT";
            this.gpsErrorMessage += " "+error;
        },

        switchTab(newTab) {
            this.$router.replace({
                query: Object.assign({}, this.$route.query,{
                page: newTab
                }),
            })
            this.actualTab = newTab;
        },
        debounceDelay() {
            setTimeout(() => this.debounceDelayElapsed = false, 3000);
        },
        gotMessageDelay(msg) {
            this.gotMessage = msg;
            setTimeout( () => this.gotMessage = '', 2000 )
        },
        checkForImageErrors() {
            //You can send data without image if: 
            // -> you are in the launch time AND it is not the first swipe of the day
        },

        imageIsRequiredAndNotAcquired() {
            const now = new Date();
            const startLaunchTime = new Date(new Date().setHours(11, 30));
            const endLaunchTime = new Date(new Date().setHours(14, 40));

            if( (now >= startLaunchTime && now <= endLaunchTime) || this.image ) {
                return false
            }
            return true
        },

        swipe() {
            this.errorMsg = "";
            if(this.imageIsRequiredAndNotAcquired() && this.selfieMandatory) {
                this.errorMsg = "Allega selfie!";
                return;
            }
            this.isLoading = true;
            this.debounceDelayElapsed = true;
            this.stopTimer();
            getMe()
            .then( () => {
                this.me = JSON.parse(localStorage.getItem("me") || "{}");
                if (this.me.email == undefined || !this.me) forceLogout();

                swipeBadge({...this.gpsData, image: this.image })
                    .then( () => { 
                        this.errorMsg = null;
                        this.image = null;
                        this.gotMessageDelay('ok');
                    })
                    .catch( (err) => {
                        this.errorMsg = err;
                        this.gotMessageDelay('not-ok');
                    })
                    .finally( ()=> {
                        this.debounceDelay();
                        getMe()
                        .then( () => {
                            this.isLoading = false;
                            this.me = JSON.parse(localStorage.getItem("me") || "{}");
                            if (this.me.email == undefined || !this.me) forceLogout();
                            if(this.me.lastLogType === 'in') {
                                this.addOneSecond();
                            }
                        })
                        this.isLoading = false;
                    })
            })
            .catch( () => {
                this.errorMsg = "Impossibile contattare il server";
                this.isLoading = false;
            })
        },
        logOut: () => forceLogout(),
    },
};
</script>

<style scoped lang="scss">

.buttons {
    position: relative;
    left: 50%;
    top: 50px;
    transform: translate(-50%, -40%);
    width: 90%;
    max-width: 660px;
}

.bottom-button {
    position: relative;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 660px;
}

.disabled {
    pointer-events: none;
    opacity: 0.4; 
}

.container {
    max-width: 660px;
    position: absolute;
    z-index:1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 84%;
    max-height: 100%;
    margin-top: 90px;
}

.title {
    background-color: rgba(255, 123, 0, 0.589);
    width: 200px;
    margin: auto;
    text-align: center;
    border-radius: 25px;
}

button {
    margin: 20px 0;
    height: 55px;
}

.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.miniature-image {
    img{
        max-width: 30vw;
    }
    display: flex;
    justify-content: space-around;
}
#refresh-gps {
    cursor: pointer;
    z-index: 10;
    position: relative;
    top: 70px; 
    color: black;
}

</style>