<template>
    <div>
        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="logOut"
            id="logout-modal"
            ok-title="Log out"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Sei sicuro di voler effettuare il logout?</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('logout-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>
        </b-modal>


        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>

        <EmployeeTopBar title="Botti-Group" @tab="switchTab"  ref="employeeTopBar"/> 

        <div :class="isLoading ? 'container disabled' : 'container'" @click="collapseNavbar()">

                    <p>
                        Specifica in quale data ha fatto o farà ore di lavoro straordinario.
                    </p>
                    <label for="date-overtime">Data</label>
                    <b-form-datepicker
                        @input="resetErrors"
                        id="date-overtime"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="overtimeRequest.overtimeDate"
                        locale="it"
                        v-bind="labels"
                        class="mb-2">
                    </b-form-datepicker>

                    <label for="start-hour">Inizio ora straordinario</label>
                    <b-form-timepicker
                        @input="resetErrors"
                        id="start-hour"
                        :state="formError"
                        minutes-step=15
                        v-model="overtimeRequest.fromTime"
                        v-bind="labels"
                        locale="it"
                        class="mb-3">
                    ></b-form-timepicker>

                    <label for="end-hour">Fine ora straordinario</label>
                    <b-form-timepicker
                        @input="resetErrors"
                        :state="formError"
                        id="end-hour"
                        minutes-step=15
                        v-model="overtimeRequest.toTime"
                        v-bind="labels"
                        locale="it"
                        class="mb-3">
                    ></b-form-timepicker>

                    <label for="textarea" class="mt-2 mb-1">Note</label>

                    <b-form-textarea
                    id="textarea"
                    v-model="overtimeRequest.comments"
                    :state="commentsLengthExceeded"
                    placeholder="Inserisci una nota..."
                    rows="3"
                     no-auto-shrink
                    ></b-form-textarea>
                    <small v-if="commentsLengthExceeded === false" class="text-danger">Nota troppo lunga.</small>

                    <b-button-group class="bottom-button">
                        <b-button 
                            block
                            pill
                            variant="secondary"
                            @click="submit()"
                        >
                        Richiedi Straordinario
                        </b-button>
                    </b-button-group> 

            <p class="text-danger" v-if="errorMsg">Errore: {{errorMsg}}</p>
        </div>
        
            <b-modal
                centered
                @show="blurBackground = true"
                @hidden="blurBackground = false"
                @ok="switchTab('home')"
                cancel-title="Riprova"
                id="response-modal"
                ok-title="Torna alla home"
                @cancel="errorMsg = ''"
                :ok-only="!errorMsg"
            >
                <template>
                    <div slot="modal-header">
                    <span class="d-inline-flex modal-header-bar">
                        <h4>
                            <strong v-if="errorMsg">Attenzione!</strong>
                            <strong v-else>Tutto ok!</strong>
                        </h4>
                        <b-icon
                        style="cursor: pointer"
                        icon="x"
                        shift-v="-4"
                        scale="1.7"
                        @click="$bvModal.hide('response-modal')"
                        ></b-icon>
                    </span>
                    </div>
                </template>
                <div slot="default">
                    <span v-if="errorMsg">{{errorMsg}}</span>
                    <span v-else>La richiesta è stata presa in carico.</span>
                </div>
        </b-modal>
    </div>


</template>

<script>
import EmployeeTopBar from "@/components/EmployeeTopBar/EmployeeTopBar.vue"
import { askOvertime, forceLogout} from "@/services/APIcalls";

export default {
    data() {
        return{
            me: {},
            isLoading: false,
            today: new Date(),
            overtimeRequest: {
                overtimeDate: null,
                fromTime: null,
                toTime: null,
                comments: '',
            },
            errorMsg: '',
            formError: null,
            labels: {
                labelPrevDecade: "Decennio precedente",
                labelPrevYear: "Anno precedente",
                labelPrevMonth: "Mese precedente",
                labelCurrentMonth: "Mese attuale",
                labelNextMonth: "Prossimo mese",
                labelNextYear: "Prossimo anno",
                labelNextDecade: "Prossimo decennio",
                labelToday: "Oggi",
                labelNoDateSelected: "Nessuna data selezionata",
                labelCalendar: "Calendario",
                labelNav: "Navigazione del calendario",
                labelHelp: "Usa i tasti freccia per navigare fra le date",
                labelHours: 'Ore',
                labelMinutes: 'Minuti',
                labelSeconds: 'Secondi',
                labelIncrement: 'Aumenta',
                labelDecrement: 'Diminuisci',
                labelNoTimeSelected: 'Nessun orario selezionato',
                labelCloseButton: 'Chiudi'
            },
        }
    },
    computed: {
        commentsLengthExceeded () {
            if (this.overtimeRequest.comments.length > 200) return false;
            return null;
        }
    },
    components: {
        EmployeeTopBar,
    },
    methods: {
        switchTab(newTab) {
            this.$emit('tab', newTab);
            this.errorMsg = '';
        },
        logOut() {
            forceLogout();
        },
        collapseNavbar(){
            this.$refs.employeeTopBar.collapseNavbar();
        },
        resetErrors() {
            this.errorMsg = "";
            this.formError = null;
        },
        submit () {
            this.formError = null;
            if(!this.overtimeRequest.overtimeDate || !this.overtimeRequest.fromTime || !this.overtimeRequest.toTime) {
                this.errorMsg = "Inserire tutti i dati per proseguire";
                this.formError = false;
                return;
            }

            const fromTimeComponents = this.overtimeRequest.fromTime.split(':');
            const toDate = new Date();
            toDate.setHours(fromTimeComponents[0], fromTimeComponents[1], fromTimeComponents[2], 0);

            const toTimeComponents = this.overtimeRequest.toTime.split(':');
            const fromDate = new Date();
            fromDate.setHours(toTimeComponents[0], toTimeComponents[1], toTimeComponents[2], 0);

            if(fromDate.getTime() <= toDate.getTime() ) {
                this.errorMsg = "Gli orari inseriti non sono validi";
                this.formError = false;
                return;
            }
 
            this.overtimeRequest.fromTime = `${this.overtimeRequest.overtimeDate}T${this.overtimeRequest.fromTime}`;
            this.overtimeRequest.toTime = `${this.overtimeRequest.overtimeDate}T${this.overtimeRequest.toTime}`;      
            this.overtimeRequest.overtimeDate = `${this.overtimeRequest.overtimeDate}T00:01:00`;

            this.isLoading = true;
            askOvertime(this.overtimeRequest).
                then(() => {
                    this.overtimeRequest.comments = '';
                    this.overtimeRequest.overtimeDate = '';
                    this.errorMsg = null;
                    this.$bvToast.toast(`Richiesta correttamente accettata`, {
                    title: `OK!`,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    solid: true
                    })
                })
                .catch( (err) => {
                    this.errorMsg = err.response.data.msg;
                    this.overtimeRequest.overtimeDate = '';
                })
                .finally( () => {
                    this.$bvModal.show('response-modal');
                    this.isLoading = false;
                })
        }
    },
    created () {
        this.me = JSON.parse(localStorage.getItem("me") || "{}");
    }
}
</script>


<style scoped>

.bottom-button {
    position: relative;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 660px;
}

button {
    margin: 20px 0;
    height: 55px;
}

.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.disabled {
    pointer-events: none;
    opacity: 0.4; 
}

.container {
    max-width: 660px;
    position: absolute;
    z-index:1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 84%;
    max-height: 100%;
    margin-top: 90px;
}

.success-text {
    margin-top: 0px;
    padding-top: 30vh;
}

</style>