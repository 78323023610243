<template>
    <div>
        <CreateNewTimeLogModal v-if="employeeCanCreateTimeLogs" @refresh="fetchItems()"/>
        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="logOut"
            id="logout-modal"
            ok-title="Log out"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Sei sicuro di voler effettuare il logout?</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('logout-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>

        </b-modal>

        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            id="timelog-forced-modal"
            ok-only
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Timbratura forzata dal sistema</strong>
                        <b-icon
                            variant="danger"
                            class="ms-2"
                            icon="exclamation-triangle"
                        ></b-icon>
                    </h5>
                </span>
                </div>
            </template>
                <p class="mt-3">
                La timbratura selezionata è stata creata in automatico dal sistema.<br>
                Se la timbratura è stata corretta si prega di ignorare questo alert.
                </p>
        </b-modal> 


        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            id="info-modal"
            ok-only
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Timbratura in revisione</strong></h5>
                </span>
                </div>
            </template>
                <p>
                    La timbratura selezionata è in fase di revisione da parte di un amministratore.<br>
                    Riceverai una email appena sarà elaborata.
                </p>
        </b-modal> 



        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>
        <EmployeeTopBar title="Botti-Group" @tab="switchTab" class="topBar" ref="employeeTopBar"/> 

        <div style="margin-top: 65px"> 
            <b-tabs fill v-model="activeTab">
                <b-tab title="Timbrature" @click="changeTab(0)">
                    <div :class="isLoading ? 'container disabled' : 'container'" @click="collapseNavbar()">
                        <h2                            
                        class="mt-3"
                        >Storico timbri <span style="font-size: 18px">{{ logDay ? ISOToString(logDay, false) : ''}}</span>
                        <b-icon
                            v-if="employeeCanCreateTimeLogs"
                            @click="$bvModal.show('create-timelog-modal');"
                            class="me-4 mt-1"
                            style="float: right; cursor: pointer;"
                            align="end"
                            icon="plus-circle"
                        ></b-icon>
                        </h2>
                        <div  v-if="logDay">
                            Torna alla vista elenco 
                            <b-icon
                                @click="changeTab(0)"
                                icon="arrow-return-right"
                                shift-v="-4"
                                scale="1.6"
                                class="mx-2"
                                style="cursor: pointer"
                            ></b-icon>

                        </div>
                        <b-table 
                            show-empty
                            ref="log-table" 
                            striped hover 
                            :items="items" 
                            :fields="fields" 
                            :busy="isLoading"
                            :tbody-tr-class="getTimeLogStatus"
                            class="mt-2"
                        >  
                            <template #cell(timeLog)="row">
                                {{ ISOToString(row.item.timeLog) }}
                            <b-icon
                                style="cursor: pointer;"
                                @click="$bvModal.show('timelog-forced-modal');"
                                v-if="row.item.forced"
                                variant="danger"
                                class="me-2"
                                icon="exclamation-triangle"
                            ></b-icon>
                            </template>

                            <template #cell(details)="row">
                            <b-icon
                                v-if="!row.item.inReviewStatus"
                                style="cursor: pointer; margin-left: 20px"
                                :class="row.detailsShowing ? 'danger-text' : ''"
                                @click="row.toggleDetails"
                                icon="pencil"
                            ></b-icon>
                            <b-icon
                                v-else
                                style="cursor: pointer; margin-left: 20px"
                                @click="$bvModal.show('info-modal');"
                                icon="info-circle"
                            ></b-icon>
                            </template>

                            <template #table-busy>
                                <div class="text-center my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                                </div>
                            </template>

                            <template #row-details="row">
                                <div class="row justify-content-md-center">
                                    <div class="col-12">
                                        <p>
                                            Inserisci l'orario corretto e conferma cliccando sul tasto "Modifica".<br>
                                            Attenzione! La modifica DEVE essere confermata da un amministratore.
                                        </p>
                                        <label for="start-hour">Orario corretto</label>
                                        <b-form-timepicker
                                            id="start-hour"
                                            minutes-step=1
                                            v-model="newTime"
                                            v-bind="labels"
                                            locale="it"
                                            class="mb-3">
                                        ></b-form-timepicker>
                                    </div>
                                </div>
                                <div class="row justify-content-md-center">
                                    <div class="col-auto-12">

                                    <b-form-group
                                    label-for="submitTakePicture"
                                    label-cols-sm="3"
                                    label-align-sm="right"
                                    style="text-align: end;"
                                    >
                                        <b-button
                                            class="mt-1"
                                            :disabled="newTime.length == 0"
                                            variant="primary"
                                            @click="selectItem(row.item)"
                                        >Modifica</b-button>
                                    </b-form-group>

                                    </div>
                                </div>
                                    <b-overlay
                                        id="overlay-background"
                                        :show="showOverlay"
                                        variant="light"
                                        opacity="0.7"
                                        blur="2px"
                                        no-wrap
                                        rounded="sm"
                                    >
                                        <template #overlay>
                                        <div
                                            ref="confirmDialog"
                                            tabindex="-1"
                                            role="dialog"
                                            aria-modal="false"
                                            aria-labelledby="form-confirm-label"
                                            class="text-center p-3"
                                        >
                                            <p>
                                            <strong id="form-confirm-label">
                                                Sei sicuro?
                                            </strong></p>
                                            <div class="d-flex">
                                            <b-button variant="outline-danger" class="mx-3" @click="onCancel">
                                                Annulla
                                            </b-button>
                                            <b-button variant="outline-success" class="mx-3" @click="submitEditTimelog()">
                                                Si
                                            </b-button>
                                            </div>
                                        </div>
                                        </template>
                                    </b-overlay>

                            </template>

                            <template #empty>
                                <p class="text-center">
                                    Nessun dato trovato
                                </p>
                            </template>
                        </b-table>
                        <b-pagination
                            v-if="!logDay"
                            v-model="actualPage"
                            :per-page="metadata['per-page']"
                            :total-rows="metadata['total-items']"
                            align="fill"
                        >
                        </b-pagination>
                    </div>
                </b-tab>
                <b-tab title="Calendario" @click="changeTab(1)">
                    <div >
                        <HoursWorkedCalendar @tab="switchTab" @showLogDay="showLogDay"/>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import $config from "@/services/config";
import CreateNewTimeLogModal from "@/components/Log/CreateNewTimeLogModal.vue";
import EmployeeTopBar from "@/components/EmployeeTopBar/EmployeeTopBar.vue";
import HoursWorkedCalendar from "@/components/Log/HoursWorkedCalendar.vue"
import {listLogs, editLog, forceLogout} from "@/services/APIcalls";

export default {
    components: {
        HoursWorkedCalendar,
        EmployeeTopBar,
        CreateNewTimeLogModal,
    },
    data() {
        return{
            logDay: '',
            employeeCanCreateTimeLogs: Boolean( $config.get('employeeCanCreateTimeLogs') ),
            activeTab: this.$route.query.view ? parseInt(this.$route.query.view) : 0,
            editingTime: '',
            newTime: '',
            editingTimeLogId: '',
            actualPage: 1,
            metadata: { 'per-page': 1, 'total-items': 100000},
            totalItems: null,
            isLoading: false,
            labels: {
                labelHours: 'Ore',
                labelMinutes: 'Minuti',
                labelSeconds: 'Secondi',
                labelIncrement: 'Aumenta',
                labelDecrement: 'Diminuisci',
                labelNoTimeSelected: 'Nessun orario selezionato',
                labelCloseButton: 'Chiudi'
            },
            fields: [
                {   key: 'timeLog',
                    label: 'Data', 
                },
                {
                    key: 'action',
                    label: 'Timbro',
                    formatter: action => (action ? 'Entrata' : 'Uscita')
                },
                { key: "details", label: "" },
            ],
            items: []
        }
    },
    mounted() {
        const tableScrollBody = this.$refs["log-table"].$el;
        /* Consider debouncing the event call */
        tableScrollBody.addEventListener("mousewheel", this.onScroll);
        this.fetchItems(1);
    },
    beforeDestroy() {
        /* Clean up just to be sure */
        const tableScrollBody = this.$refs["log-table"].$el;
        tableScrollBody.removeEventListener("mousewheel", this.onScroll);
    },
    methods: {
        getTimeLogStatus(item, type){
            if (type !== 'row') return 'table-row';
            return `table-row ${item.inReviewStatus ? 'warning-row' : ''}`
        },
        showLogDay(day) {
            this.activeTab = 0;
            this.logDay = day;
            console.log(day);
            this.fetchItems(day);
        },
        changeTab(view) {
            this.activeTab = view;

            if( this.$route.query.view != view ) {
                this.$router.replace({
                    query: Object.assign({},this.$route.query, {
                    view: view
                    }),
                });
            }
            
            if( this.logDay ) {
                this.logDay = '';
                this.fetchItems();
            }

        },
        selectItem(item){
            this.editingTimeLogId = item.id;
            this.editingTime = item['timeLog'];
        },
        collapseNavbar(){
            this.$refs.employeeTopBar.collapseNavbar();
        },
        switchTab(newTab) {
            this.$emit('tab', newTab)
        },
        logOut() {
            forceLogout();
        },
        onCancel(){
            this.editingTimeLogId = '';
            this.editingTime = '';
        },
        submitEditTimelog(){
            const hours = this.newTime.split(':');
            const date = new Date(this.editingTime);
            date.setHours(...hours);
            this.isLoading = true;
            editLog({newDate: date.toISOString()},this.editingTimeLogId)
            .finally( () => {
                this.onCancel();
                this.fetchItems();
                this.isLoading = false;
            })
        },
        onScroll(event) {
            console.log("SCROLL;", event);
            if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight
            ) {
                if (!this.isLoading) {
                this.fetchItems();
                }
            }
        },
        async fetchItems(day = null) {
            if (this.items.length === this.totalItems) return;
            this.isLoading = true;
            listLogs(this.actualPage, day)
                .then(res => {
                    this.items = res.data.timeLogs;
                    this.metadata = res.data.metadata;
                })
                .catch((err) => {
                    this.items = [
                        {'date': 'server error'},
                        {'check': err}
                    ]
                })
                .finally ( () => this.isLoading = false)
            return null
        },
        ISOToString: function(ISODate, hideTime = true) {
            const date = new Date(ISODate)
            const year = date.getFullYear();
            const month = (date.getMonth()+1) < 10 ? '0'+ (date.getMonth()+1) : (date.getMonth()+1);
            const day = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
            const hours =date.getHours() < 10 ? '0'+ date.getHours() : date.getHours();
            const minutes = date.getMinutes() < 10 ? '0'+ date.getMinutes() : date.getMinutes();

            if (!hideTime) {
                 return `${day}/${month}/${year}`;
            }

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
    },
    watch: {
        actualPage: function () {
            this.fetchItems();
        },
    },
    computed: {
        showOverlay: function () {
            return this.editingTimeLogId.length > 0;
        }
    }
}
</script>


<style scoped>

.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.disabled {
    pointer-events: none;
    opacity: 0.4; 
}

.container {
    max-width: 660px;
    position: absolute;
    z-index:1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 84%;
    max-height: 100%;
    margin-top: 90px;
}

</style>