<template>
    <div>
        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="logOut"
            id="logout-modal"
            ok-title="Log out"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Sei sicuro di voler effettuare il logout?</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('logout-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>
            <p class="my-4">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa iure
                corrupti dolorum
            </p>
        </b-modal>

        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>

        <EmployeeTopBar title="Botti-Group" @tab="switchTab"  ref="employeeTopBar"/> 

        <div :class="isLoading ? 'container disabled' : 'container'" v-if="passwordIsNotChanged" @click="collapseNavbar()">
            <h1>Cambia password</h1>

            <b-row class="my-3 mt-5">
                <b-col sm="3">
                    <label for="old-password">Inserisci la vecchia password:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input 
                        id="old-password"
                        ref="1"
                        @keyup.enter="enterClicked('new-password')"
                        v-model="oldPassword"
                        :state="oldPasswordIsCorrect === null ? null : !oldPasswordIsCorrect"
                        placeholder="Vecchia password"
                        type="password"
                    ></b-form-input>
                    <b-form-invalid-feedback id="old-password">
                        {{ oldPasswordIsCorrect }}
                    </b-form-invalid-feedback>
                </b-col>
            </b-row>

            <b-row class="my-3">
                <b-col sm="3">
                    <label for="new-password">Inserisci la nuova password:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input 
                        v-model="newPassword" 
                        ref="2"
                        @keyup.enter="enterClicked('repeat-new-password')"
                        id="new-password" 
                        :state="newPasswordError === null ? null : !newPasswordError"
                        placeholder="Nuova password" type="password"
                    ></b-form-input>
                </b-col>
            </b-row>

            <b-row class="my-3">
                <b-col sm="3">
                    <label for="repeat-new-password">Ripeti la nuova password:</label>
                </b-col>
                <b-col sm="9">
                    <b-form-input 
                        v-model="repeatNewPassoword" 
                        ref="3"
                        @keyup.enter="enterClicked('')"
                        id="repeat-new-password" 
                        :state="newPasswordError === null ? null : !newPasswordError"
                        placeholder="Ripeti nuova password" type="password"
                    ></b-form-input>
                    <b-form-invalid-feedback id="repeat-new-password">
                        {{ this.newPasswordError }}
                    </b-form-invalid-feedback>
                </b-col>

                <b-col sm="9">

                </b-col>
            </b-row>

            <b-row class="my-3">
                <b-col lg="4" class="py-3" @click="changePassword()">
                    <b-button squared>
                    Cambia password
                    </b-button>
                </b-col>
            </b-row>

        </div>
        <div class="container success-text px-4" v-else>
            <h1 style="color: var(--primary);" class="text-center">Complimenti!</h1>
            <h2>La password è stata cambiata correttamente</h2>
            <h4>Verrai reindirizzato automaticamente alla home fra <p style="color: var(--primary); display: inline;"> {{countDown}} </p> secondi</h4>
            
        </div>
        
    </div>
</template>

<script>
import EmployeeTopBar from "@/components/EmployeeTopBar/EmployeeTopBar.vue"
import {changePassword, forceLogout} from "@/services/APIcalls";

export default {
    data() {
        return{
            oldPassword: "",
            newPassword: "",
            repeatNewPassoword: "",
            oldPasswordIsCorrect : null,
            newPasswordError : null,
            isLoading: false,
            passwordIsNotChanged: true,
            countDown: 10,
        }
    },
    components: {
        EmployeeTopBar,
    },
    methods: {
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 500)
            }
            else this.$emit('tab', 'home')
        },
        switchTab(newTab) {
            this.$emit('tab', newTab)
        },
        logOut() {
            forceLogout();
        },
        collapseNavbar(){
            this.$refs.employeeTopBar.collapseNavbar();
        },
        changePassword() {
            if(this.newPassword !== this.repeatNewPassoword) this.newPasswordError = 'Le password inserite non coincidono';
            else if( this.newPassword.length < 5 ) this.newPasswordError = 'La nuova password deve essere lunga almeno 5 caratteri';
            else this.newPasswordError = false;
            if (this.oldPassword.length === 0) this.oldPasswordIsCorrect = 'Il campo password non può essere vuoto';
            else this.oldPasswordIsCorrect = null;
            
            if( this.newPasswordError !== false || this.oldPasswordIsCorrect !== null ) return

            this.isLoading = true;
            changePassword({oldPassword: this.oldPassword, newPassword: this.newPassword})
                .then( () => {
                    this.oldPasswordIsCorrect = null;
                    this.passwordIsNotChanged = false;
                    this.countDownTimer();
                })
                .catch( () => this.oldPasswordIsCorrect = 'Password errata' )
                .finally( ()=> this.isLoading = false )
        },
        enterClicked ( nextElem ){
            if (this.oldPassword !== '' && this.newPassword !== '' && this.repeatNewPassoword !== '') this.changePassword();
            if ( nextElem) document.getElementById(nextElem).focus();
            else this.changePassword();

        }
    },
}
</script>


<style scoped>

.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.disabled {
    pointer-events: none;
    opacity: 0.4; 
}

.container {
    max-width: 660px;
    position: absolute;
    z-index:1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 84%;
    max-height: 100%;
    margin-top: 90px;
}

.success-text {
    margin-top: 0px;
    padding-top: 30vh;
}
</style>