<template>
    <b-navbar toggleable type="dark" variant="secondary" class="d-flex">
      <div class="container">
            <b-navbar-brand @click="$emit('tab', 'home')">{{title}}</b-navbar-brand>
            <b-navbar-toggle target="pippo" @click="toggleCollapse"></b-navbar-toggle>
            <b-collapse v-model="visible" id="nav-collapse" is-nav class="align-items-end"> 
                    <b-navbar-nav  class="align-items-end links">
                      <b-nav-item class="links" @click="$emit('tab', 'home')">Home</b-nav-item >
                        <b-nav-item class="links" @click="$emit('tab', 'log')">Storico</b-nav-item >
                        <b-nav-item class="links" @click="$emit('tab', 'ask-vacation')" v-if="enableRequestVacation">Ferie|Permessi|Malattia</b-nav-item >
                        <b-nav-item class="links" @click="$emit('tab', 'ask-overtime')" v-if="enableRequestOvertime">Straordinari</b-nav-item >  
                        <b-nav-item class="links" @click="$emit('tab', 'change-password')">Cambia password</b-nav-item >
                        <b-nav-item v-b-modal.logout-modal>Logout</b-nav-item>
                    </b-navbar-nav>
            </b-collapse>
      </div>  
    </b-navbar>
</template>

<script>
import $config from "@/services/config";

export default {
  name: 'EmployeeTopBar',
  props: {
    title: String
  },
  data(){
    return {
      enableRequestVacation : Boolean($config.get("enableRequestVacation")),
      enableRequestOvertime: Boolean( $config.get("enableRequestOvertime")),
      visible: false,
    }
  },
  methods: {
    toggleCollapse(){
      this.visible = !this.visible;
    },
    collapseNavbar(){
      this.visible = false;
    }
  }
}
</script>

<style scoped>
.navbar {
    position: absolute;
    z-index:40;
    top: 0;
    width: 100%;
}
.nav-link{
  font-size: 20px;
  line-height: 38px;
}

</style>