<template>
    <div>
        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="logOut"
            id="logout-modal"
            ok-title="Log out"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Sei sicuro di voler effettuare il logout?</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('logout-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>
        </b-modal>


        <b-modal
            centered
            @show="blurBackground = true"
            @hidden="blurBackground = false"
            @ok="postDatedIsOk"
            id="postdated-modal"
            ok-title="Continua"
            cancel-title="Annulla"
        >
            <template>
                <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h4><strong>Attenzione</strong></h4>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('postdated-modal')"
                    ></b-icon>
                </span>
                </div>
            </template>
            <div slot="default">
                Stai per inviare una richiesta postdatata. Sei sicuro di voler continuare?
            </div>
        </b-modal>


        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>

        <EmployeeTopBar title="Botti-Group" @tab="switchTab"  ref="employeeTopBar"/> 

        <div :class="isLoading ? 'container disabled' : 'container'" v-if="passwordIsNotChanged" @click="collapseNavbar()">


            <b-tabs content-class="mt-3" fill v-model="activeTab">

                <b-tab title="Ferie"  @click="changeTab(0)" lazy>
                    <p>

                        <span v-if="me.paysplip !== null">
                            <span v-if=" getSafe(() => me.payslip.vacationRemainingHoursAP) || getSafe(() => me.payslip.vacationRemainingHoursAC) ">
                            Dalla busta paga del {{me.payslip.date}} risultano <b>{{ hoursToDays(me.payslip.vacationRemainingHoursAP, me.payslip.vacationRemainingHoursAC) }}</b> giorni di ferie.<br>
                            </span>
                        </span>

                        Richiedi le ferie in un dato intervallo di date. Appena la tua richiesta sarà revisionata
                        riceverai una risposta su:<br><b>{{email}}</b>
                    </p>
                    <label for="start-vacation">Inizio ferie</label>
                    <b-form-datepicker
                        id="start-vacation"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="startVacation"
                        locale="it"
                        v-bind="labels"
                        class="mb-2">
                    </b-form-datepicker>

                    <label for="end-vacation">Fine ferie</label>
                    <b-form-datepicker
                        id="end-vacation"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="endVacation"
                        locale="it"
                        v-bind="labels"
                        class="mb-2">
                    </b-form-datepicker>

                    <label for="textarea" class="mt-2 mb-1">Note</label>

                    <b-form-textarea
                    id="textarea"
                    v-model="comments"
                    :state="commentsLengthExceeded"
                    placeholder="Inserisci una nota (opzionale)..."
                    rows="3"
                     no-auto-shrink
                    ></b-form-textarea>
                    <small v-if="commentsLengthExceeded === false" class="text-danger">Nota troppo lunga.</small>

                    <b-button-group class="bottom-button">
                        <b-button 
                            block
                            pill
                            variant="secondary"
                            @click="askForVacation('vacation')"
                        >
                        Richiedi ferie
                        </b-button>
                    </b-button-group> 

                </b-tab>
                <b-tab title="Permessi" @click="changeTab(1)" lazy>
                    <p>
                         Richiedi un permesso orario per un dato giorno.<br>
                        <span v-if="me.paysplip !== null">
                            <span v-if="hasPermissionHours">
                            Dalla busta paga del {{me.payslip.date}} risultano:<br>
                                <span v-if="getSafe(() => me.payslip.festivityRemainingHoursAP) || getSafe(() => me.payslip.festivityRemainingHoursAC)">
                                    <b>{{ sumHours(me.payslip.festivityRemainingHoursAP, me.payslip.festivityRemainingHoursAC) }}</b> ore di festività non godute.<br>
                                </span>
                                <span v-if="getSafe(() => me.payslip.permissionsRemainingHoursAP) || getSafe(() => me.payslip.permissionsRemainingHoursAC)">
                                    <b>{{ sumHours(me.payslip.permissionsRemainingHoursAP, me.payslip.permissionsRemainingHoursAC) }}</b> ore di permesso.<br>
                                </span>
                            </span>
                        </span>

                       Appena la tua richiesta sarà revisionata riceverai una risposta su:<br><b>{{email}}</b>
                    </p>
                    <label for="permission-date">Data permesso</label>
                    <b-form-datepicker
                        id="permission-date"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="permissionDate"
                        locale="it"
                        v-bind="labels"
                        class="mb-3">
                    </b-form-datepicker>

                    <label for="start-hour">Inizio ora permesso</label>
                    <b-form-timepicker
                        id="start-hour"
                        :state="formError"
                        minutes-step=1
                        v-model="startHour"
                        v-bind="labels"
                        locale="it"
                        class="mb-3">
                    ></b-form-timepicker>

                    <label for="end-hour">Fine ora permesso</label>
                    <b-form-timepicker
                        :state="formError"
                        id="end-hour"
                        minutes-step=1
                        v-model="endHour"
                        v-bind="labels"
                        locale="it"
                        class="mb-3">
                    ></b-form-timepicker>

                    <label for="textarea" class="mt-2 mb-1">Note</label>

                    <b-form-textarea
                    id="textarea"
                    v-model="comments"
                    :state="commentsLengthExceeded"
                    placeholder="Inserisci una nota (opzionale)..."
                    rows="3"
                     no-auto-shrink
                    ></b-form-textarea>
                    <small v-if="commentsLengthExceeded === false" class="text-danger">Nota troppo lunga.</small>

                    <b-button-group class="bottom-button">
                        <b-button 
                            block
                            pill
                            variant="secondary"
                            @click="askForVacation('hours')"
                        >
                        Richiedi permesso
                        </b-button>
                    </b-button-group>                 
                </b-tab>




                <b-tab title="Malattia" @click="changeTab(2)" lazy>
                    <p>
                        Segnala il periodo di malattia tramite questo form. È obbligatorio inserire il numero di impegnativa nel campo Note.
                    </p>
                    <label for="start-sickLeave">Inizio Malattia</label>
                    <b-form-datepicker
                        id="start-sickLeave"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="startSickLeave"
                        locale="it"
                        v-bind="labels"
                        class="mb-2">
                    </b-form-datepicker>

                    <label for="end-sickLeave">Fine Malattia</label>
                    <b-form-datepicker
                        id="end-sickLeave"
                        :state="formError"
                        menu-class="w-100"
                        calendar-width="100%"
                        v-model="endSickLeave"
                        locale="it"
                        v-bind="labels"
                        class="mb-2">
                    </b-form-datepicker>

                    <label for="textarea" class="mt-2 mb-1">Note</label>

                    <b-form-textarea
                    id="textarea"
                    v-model="comments"
                    :state="commentsLengthExceeded"
                    placeholder="Inserisci una nota*"
                    rows="3"
                     no-auto-shrink
                    ></b-form-textarea>
                    <small v-if="commentsRequired === true" class="text-danger">Note obbligatorie!</small>
                    <small v-if="commentsLengthExceeded === false" class="text-danger">Nota troppo lunga.</small>

                    <b-button-group class="bottom-button">
                        <b-button 
                            block
                            pill
                            variant="secondary"
                            @click="askForVacation('sick')"
                        >
                        Invia
                        </b-button>
                    </b-button-group> 
                </b-tab>

                <b-tab title="Richieste" @click="changeTab(3)" lazy>
                    <p>
                        Qui di seguito troverai le richieste effettuate.<br>
                    </p>

                    <VacationRequestHistory ref="vacationRequestHistory"/>

                </b-tab>
            </b-tabs>
            <p class="text-danger">{{errorMsg}}</p>
        </div>


        <div class="container success-text px-4" v-else>
            <h1 style="color: var(--primary);" class="text-center">Complimenti!</h1>
            <h2>La password è stata cambiata correttamente</h2>
            <h4>Verrai reindirizzato automaticamente alla home fra <p style="color: var(--primary); display: inline;"> {{countDown}} </p> secondi</h4>
            
        </div>
        
    </div>
</template>

<script>
import VacationRequestHistory from "@/components/VacationRequest/VacationRequestHistory.vue"
import EmployeeTopBar from "@/components/EmployeeTopBar/EmployeeTopBar.vue"
import {askVacation, forceLogout} from "@/services/APIcalls";

export default {
    data() {
        return{
            me: {},
            activeTab: this.$route.query.tab ? parseInt(this.$route.query.tab) : 3,
            prevRequestType: '',
            allowPostdsted: false,
            commentsRequired: false,
            isLoading: false,
            passwordIsNotChanged: true,
            countDown: 10,
            today: new Date(),
            startVacation: null,
            endVacation: null,
            startSickLeave: null,
            endSickLeave: null,
            permissionDate: null,
            startHour: null,
            endHour:null,
            errorMsg: '',
            formError: null,
            comments: '',
            email: (JSON.parse(localStorage.getItem("me") || "{}") || {} ).email,
            labels: {
                labelPrevDecade: "Decennio precedente",
                labelPrevYear: "Anno precedente",
                labelPrevMonth: "Mese precedente",
                labelCurrentMonth: "Mese attuale",
                labelNextMonth: "Prossimo mese",
                labelNextYear: "Prossimo anno",
                labelNextDecade: "Prossimo decennio",
                labelToday: "Oggi",
                labelNoDateSelected: "Nessuna data selezionata",
                labelCalendar: "Calendario",
                labelNav: "Navigazione del calendario",
                labelHelp: "Usa i tasti freccia per navigare fra le date",
                labelHours: 'Ore',
                labelMinutes: 'Minuti',
                labelSeconds: 'Secondi',
                labelIncrement: 'Aumenta',
                labelDecrement: 'Diminuisci',
                labelNoTimeSelected: 'Nessun orario selezionato',
                labelCloseButton: 'Chiudi'
            },
        }
    },
    computed: {
        hasPermissionHours() {
            return (
                this.getSafe(() => this.me.payslip.festivityRemainingHoursAP ) || this.getSafe(() => this.me.payslip.festivityRemainingHoursAC )
                || this.getSafe(() => this.me.payslip.permissionsRemainingHoursAP ) || this.getSafe(() => this.me.payslip.permissionsRemainingHoursAC )
            )
        },
        commentsLengthExceeded () {
            if (this.comments.length > 200) return false;
            return null;
        }
    },
    components: {
        VacationRequestHistory,
        EmployeeTopBar,
    },
    methods: {
        sumHours( hoursAP, hoursAC ){
            return Number((((hoursAP ?? 0) + (hoursAC ?? 0))).toFixed(2));
        },
        hoursToDays( hoursAP, hoursAC ){
            return Number((((hoursAP ?? 0) + (hoursAC ?? 0))/ this.me.contractHours).toFixed(1));
        },
        changeTab(view) {
            this.activeTab = view;
            if( this.$route.query.tab != view ) {
                this.$router.replace({
                    query: Object.assign({},this.$route.query, {
                    tab: view
                    }),
                });
            }
        },
        isPostdated(vacationRequest) {
            const start = new Date(vacationRequest.startDate);
            const end = new Date(vacationRequest.endDate);
            if(start < this.today || end <= this.today)
            {
                return true;
            }
            else return false;
        },
        postDatedIsOk() {
            this.allowPostdsted = true;
            this.askForVacation(this.prevRequestType);
        },
        switchTab(newTab) {
            this.$emit('tab', newTab)
        },
        logOut() {
            forceLogout();
        },
        getSafe(fn, defaultVal = false) {
            try {
                return fn();
            } catch (e) {
                return defaultVal;
            }
        },
        askForVacation(requestType){
            this.formError = null;
            this.errorMsg = "";
            this.commentsRequired = false;
            const vacationRequest = {
                requestType: requestType,
                employeeComments: this.comments,
            };
            if( requestType == 'vacation') {           
                if(!this.startVacation || !this.endVacation) {
                    this.errorMsg = "Inserire le date per proseguire";
                    this.formError = false;
                    return;
                }
                vacationRequest.startDate = `${this.startVacation}T00:01:00`;
                vacationRequest.endDate = `${this.endVacation}T00:01:00`;    
            }
            else if( requestType == 'hours'){
                if(!this.startHour || !this.endHour || !this.permissionDate) {
                    this.errorMsg = "Inserire le date per proseguire";
                    this.formError = false;
                    return;
                }
                vacationRequest.startDate = `${this.permissionDate}T${this.startHour}`;
                vacationRequest.endDate = `${this.permissionDate}T${this.endHour}`;  
            }

            else if( requestType == 'sick'){
                if(!this.startSickLeave || !this.endSickLeave) {
                    this.errorMsg = "Inserire le date per proseguire";
                    this.formError = false;
                    return;
                }
                if(!this.comments){
                    this.commentsRequired = true;
                    return;
                }
                vacationRequest.startDate = this.startSickLeave;
                vacationRequest.endDate = this.endSickLeave; 
            }

            if( this.isPostdated(vacationRequest) && !this.allowPostdsted ){
                this.prevRequestType = requestType;
                this.$bvModal.show('postdated-modal');
                return;
            }
            this.prevRequestType = '';
            this.allowPostdsted = false;
            this.isLoading = true;
            askVacation(vacationRequest)
                .then( () => {
                    this.comments = '';
                    this.startVacation = '';
                    this.endVacation = '';
                    this.permissionDate = '';
                    this.startHour = '';
                    this.endHour = '';
                    this.errorMsg = null;
                    //todo: add ok message
                    this.activeTab = 3;
                })
                .catch( (err) => {
                    this.errorMsg = err;
                    //todo: add not ok message
                })
                .finally( ()=> {
                    this.isLoading = false;
                })
        },
        collapseNavbar(){
            this.$refs.employeeTopBar.collapseNavbar();
        },
    },
    created () {
        this.me = JSON.parse(localStorage.getItem("me") || "{}");
    }
}
</script>


<style scoped>

.bottom-button {
    position: relative;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 660px;
}

button {
    margin: 20px 0;
    height: 55px;
}

.loader {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.disabled {
    pointer-events: none;
    opacity: 0.4; 
}

.container {
    max-width: 660px;
    position: absolute;
    z-index:1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: 84%;
    max-height: 100%;
    margin-top: 90px;
}

.success-text {
    margin-top: 0px;
    padding-top: 30vh;
}

</style>