<template> 
    <div>
        <div class="text-center loader" v-if="isLoading">
            <b-spinner 
                style="width: 4rem; height: 4rem;" 
                variant="secondary"
            >
            </b-spinner>
        </div>

        <div :class="isLoading ? 'disabled' : ''">
            <b-table ref="vacation-table"
                show-empty
                hover 
                :tbody-tr-class="getVacationRequestStatus"
                :items="items"
                :fields="fields"
                :busy="isLoading"
            >  
                <template #cell(check)="data">
                <b :class="data.item.warning ? 'danger-text' : ''">{{ data.value }}</b>
                </template>

                <template #cell(details)="row">
                <b-icon
                    style="cursor: pointer; margin-left: 20px"
                    :class="row.detailsShowing ? 'danger-text' : ''"
                    @click="row.toggleDetails"
                    icon="search"
                ></b-icon>
                </template>

                <template #table-busy>
                    <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #row-details="row">
                    <div class="row mr-5 justify-content-md-center">
                        <div class="col-8">
                            <div class="my-1" v-if="row.item.requestType == 'hours'">
                                <b>Per il giorno: {{ formatDates(row.item.startDate, 'vacation', true) }}</b>
                            </div>
                            <div class="my-1">
                                <b>Inizio:</b>
                                {{ formatDates(row.item.startDate, row.item.requestType, true) }}
                            </div>

                            <div class="my-1">
                                <b>Fine:</b>
                                {{formatDates(row.item.endDate, row.item.requestType, true)}}
                            </div>

                            <div class="my-1" v-if="row.item.employeeComments">
                                <b>Note dipendente:</b>
                                <p>
                                    {{row.item.employeeComments}}
                                </p>
                            </div>

                            <div class="my-1" v-if="row.item.adminComments">
                                <b>Note Amministratore:</b>
                                <p>
                                    {{row.item.adminComments}}
                                </p>
                            </div>

                            <div 
                            v-if="row.item.requestType !== 'sick'"
                            :class="`my-1 text-${statusToCss(row.item.state)}`">
                                <b>Stato: {{statusToItalian(row.item.state)}}.</b>
                            </div>
                        </div>
                        <div
                            v-if="row.item.state == 'accepted'" 
                            class="col-4"
                        >
                            <b-button
                                v-if="row.item.requestType !== 'sick'"
                                class="mt-5"
                                variant="danger"
                                @click="renounceRequestId = row.item.id"
                            >Rinuncia</b-button>
                        </div>

                    <b-overlay
                        id="overlay-background"
                        :show="showOverlay"
                        variant="light"
                        opacity="0.7"
                        blur="2px"
                        no-wrap
                        rounded="sm"
                    >
                        <template #overlay>
                        <div
                            ref="confirmDialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="text-center p-3"
                        >
                            <p>
                            <strong id="form-confirm-label">
                                Sei sicuro di voler rinunciare ?
                            </strong></p>
                            <div class="d-flex">
                            <b-button variant="outline-danger" class="mx-3" @click="onCancel">
                                Annulla
                            </b-button>
                            <b-button variant="outline-success" class="mx-3" @click="renounceRequest()">Si</b-button>
                            </div>
                        </div>
                        </template>
                    </b-overlay>

                    </div>
                </template>

    <template #empty>
        <p class="text-center">
            Nessun dato trovato
        </p>
    </template>


            </b-table>
            <b-pagination 
                v-model="actualPage"
                :per-page="metadata['per-page']"
                :total-rows="metadata['total-items']"
                align="fill"
            >
            </b-pagination>
        </div>
    </div>
</template>

<script>
import {listVacationRequest, renounceVacation} from "@/services/APIcalls";

export default {
    data() {
        return{
            actualPage: 1,
            renounceRequestId: '',
            metadata: { 'per-page': 1, 'total-items': 100000},
            totalItems: null,
            isLoading: false,
            fields: [
                {   key: 'createdAt',
                    label: 'Inviata il', 
                    formatter: ISODate => this.formatDates(ISODate)
                },
                {
                    key: 'requestType',
                    label: 'Richiesta',
                    formatter: request => {
                        if(request == 'hours'){
                            return 'Permesso';
                        }
                        else if (request === 'vacation'){
                            return 'Ferie';
                        }    
                        
                        return 'Malattia';
                    }
                },
                {
                    key: 'details',
                    label: 'Dettagli',
                }
            ],
            items: []
        }
    },
    mounted() {
        const tableScrollBody = this.$refs["vacation-table"].$el;
        tableScrollBody.addEventListener("mousewheel", this.onScroll);
        this.fetchItems(1);
    },
    beforeDestroy() {
        const tableScrollBody = this.$refs["vacation-table"].$el;
        tableScrollBody.removeEventListener("mousewheel", this.onScroll);
    },
    methods: {
        formatDates(ISODate, requestType = null, excludeTimeZone = false) {
            if(null == ISODate) return '';
            var internalISODate = ISODate;
            if(excludeTimeZone) {
                internalISODate = ISODate.slice(0, 19);
            }
            const date = new Date(internalISODate);
            const year = `${date.getFullYear()}`;
            const month = `${
                date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1
            }`;
            const day = `${
                date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
            }`;

            if (internalISODate.length < 12 ) return `${day}/${month}/${year}`;
            const hours = `${
                date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
            }`;
            const minutes = `${
                date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
            }`;

            if (requestType == 'hours'){
                return  `${hours}:${minutes}`;
            }
            if (['vacation', 'sick'].includes(requestType)){
                return  `${day}/${month}/${year}`;
            }
            return `${day}/${month}/${year} - ${hours}:${minutes}`;
            },
        getVacationRequestStatus(item, type){
        if (type !== 'row') return 'table-row';
        return `table-row ${this.statusToCss(item.state)}-row`
        },
        onScroll(event) {
            console.log("SCROLL;", event);
            if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight
            ) {
                if (!this.isLoading) {
                this.fetchItems();
                }
            }
        },
        async fetchItems() {
            if (this.items.length === this.totalItems) {
                //todo: no items
                this.$emit('vacationRequests', 'EMPTY');
                return;
            }
            this.isLoading = true;
            listVacationRequest({page: this.actualPage})
                .then(res => {
                    this.items = res.data.vacationRequests;
                    this.metadata = res.data.metadata;
                })
                .catch((err) => {
                    this.items = [
                        {'date': 'server error'},
                        {'check': err}
                    ]
                })
                .finally ( () => this.isLoading = false)
            return null
        },
        statusToItalian(status){
            if ( status == 'accepted') {
                return 'Approvato';
            }
            if ( status == 'deleted') {
                return 'Eliminato';
            }
            if ( status == 'rejected') {
                return 'Rifiutato';
            }
            if ( status == 'renounced') {
                return "riunciato dal dipendente";
            }
                return 'In elaborazione';
            },
        statusToCss(status){
            if ( status == 'accepted') {
                return 'success';
            }
            if ( status == 'rejected' || status == 'renounced' || status == 'deleted') {
                return 'danger';
            }
                return 'warning';
            },
        onCancel(){
            this.renounceRequestId = '';
        },
        renounceRequest(){
            renounceVacation(this.renounceRequestId)
            .finally( () => {
                this.renounceRequestId = '';
                this.fetchItems();
            })
        }
    },
    watch: {
        actualPage: function () {
            this.fetchItems();
        },
    },
    computed: {
        showOverlay: function () {
            return this.renounceRequestId.length > 0;
        }
    }

}
</script>