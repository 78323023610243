<template>
    <div style="height: 100%">
        <div class="text-center py-2 loader" v-show="dataIsLoading">
          <b-spinner></b-spinner>
          <div>
            <strong>Caricamento...</strong>
          </div>
        </div>
        
       <v-calendar
        :class="dataIsLoading ? 'blur-content' : ''"
        class="custom-calendar max-w-full"
        locale="it-IT"
        :masks="masks"
        :attributes="attributes"
        disable-page-swipe
        v-model="date"
        is-expanded
        @update:to-page="updateCalendar"
        :from-page="fromDate"
        :key="refreshKey"
        >

        <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10 overflow-hidden">
              <span
                style="cursor: pointer"
                @click="goToLogDay(day)"
                class="day-label text-sm text-gray-900"
              >
              {{ day.day }}
              </span>
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <span v-for="attr in attributes" :key="attr.id">
                  <!--
                  <span v-if="attr.customData.plusMinusMinutes" class="pointer mb-2">
                    <p :class="`leading-tight plus-minus ${attr.customData.plusMminusMinutesClass}`">
                      {{attr.customData.plusMinusMinutes}}
                    </p>
                  </span>
                  -->
                  <span v-if="attr.customData.forced" class="pointer mb-2" @click="goToLogDay(day)">
                    <b-icon
                      style="cursor: pointer;"
                      variant="danger"
                      class="leading-tight plus-minus mt-1 me-1"
                      icon="exclamation-triangle"
                    ></b-icon>
                  </span>
                  <div class="mt-1">
                    <p
                      v-if="attr.customData.overTime || attr.customData.unpaidVacationMinutes"
                      :class="`${attr.customData.overTimeClass} text-left text-justify leading-tight p-1 mt-0 mb-1 text-white calendar-font`"
                    >
                      {{ attr.customData.overTime ? '(straordinario)' : '-' }}
                      {{ attr.customData.overTime ? attr.customData.overTime : attr.customData.unpaidVacationMinutes}}
                  
                      <b-icon
                        v-if="attr.customData.unpaidVacationMinutes"
                        @click="goToAskPermissionPage()"
                        icon="arrow-return-right"
                        shift-v="-4"
                        scale="1.7"
                        class="mx-2"
                        style="cursor: pointer"
                      ></b-icon>
                    </p>
                    <p
                      :to="{ path: '/', query: { page: 'log',view: 0}}" 
                      v-if="attr.customData.hoursWorked"
                      :class="`${attr.customData.classHoursWorked} text-left text-justify leading-tight p-1 mt-0 mb-1 calendar-font`"
                    >
                    {{ attr.customData.hoursWorked }}
                      <b-icon
                        @click="goToLogDay(day)"
                        icon="arrow-return-right"
                        shift-v="-4"
                        scale="1.7"
                        class="mx-2"
                        style="cursor: pointer"
                      ></b-icon>
                    </p>
                  </div>
                  <div class="mt-2">
                    <p
                      :to="{ path: '/', query: { page: 'ask-vacation'}}" 
                      v-if="attr.customData.hoursVacation"
                      :class="`${attr.customData.classHoursVacation} text-left text-justify leading-tight p-1 mt-0 mb-1 calendar-font`"
                    >
                    {{ attr.customData.hoursVacation }} ({{ attr.customData.vacationType }})
                    </p>
                  </div>
                </span>
              </div>
            </div>
        </template>
        </v-calendar>
    </div>
</template>

<script>
import  { listMontlyHoursWorked }  from "@/services/APIcalls.js";

export default {
    props: {
        fromDate: Object
    },
    data() {
        return {
            date: {},
            masks: {
                weekdays: 'WWW',
            },
            dataIsLoading: true,
            attributes: [ ],
            refreshKey: 0,
        }
    },
    methods: {
    goToLogDay (date) {
      const dateToPush = date.date;
      dateToPush.setHours(5);
      //this.$router.push({ query: { page: 'log', view: 0 , day: dateToPush.toISOString().substring(0,10)} });
      this.$emit('showLogDay', dateToPush.toISOString().substring(0,10) );
    },
    goToAskPermissionPage () {
      this.$emit('tab', 'ask-vacation');
      this.$router.push({ query: { page: 'ask-vacation', tab: 0 } })
    },
    minutesToHoursMinutes(minutes, enableTrueCalculator = false) {
      const plus = enableTrueCalculator ? '+' : '';
      const hours = (Math.abs(minutes) / 60);
      var rhours = Math.floor(hours);
      var minutesComputed = (hours - rhours) * 60;
      var rminutes = Math.round(minutesComputed);
      if(rhours == 0) {
        return `${minutes < 0 ? '-' : plus} ${rminutes} minuti.`;
      }
      return `${minutes < 0 ? '-' : plus} ${rhours} or${rhours == 1 ? 'a' : 'e'} e ${rminutes} minut${rminutes == 1 ? 'o' : 'i'}.`;
    },
      refreshCalendar() {
        this.refreshKey ++;
      },
      updateCalendar (newDateObject) {
        this.date = newDateObject;
        this.dataIsLoading = true;
        listMontlyHoursWorked({...newDateObject})
        .then((res) => {
            this.attributes = res.data.hoursWorkedMontly;
            this.attributes.forEach(
                (obj) => {
                    obj.dates = new Date(obj["date"]);
                    obj.customData = {
                      forced: obj.forced,
                      hoursWorked: obj.minutesWorkedAmount == 0 ? null : this.minutesToHoursMinutes(obj.minutesWorkedAmount),
                      classHoursWorked: obj.minutesWorkedVsContractMinutes >= 0 ? 'bg-teal-500 text-white' : 'bg-red-600 text-white',
                      hoursVacation: obj.vacationMinutes == 0 ? null : this.minutesToHoursMinutes(obj.vacationMinutes),
                      classHoursVacation: obj.vacationType ? `vacation-${obj.vacationType}` : '',
                      vacationType: obj.vacationType == 'sick' ? 'malattia' : (obj.vacationType == 'hours' ? 'permesso' : (obj.vacationType == 'vacation' ? 'ferie' : (obj.vacationType == 'festivity' ? 'festività' : ''))),
                      overTime: obj.overTime == 0 ? null : this.minutesToHoursMinutes(obj.overTime),
                      unpaidVacationMinutes: obj.unpaidVacationMinutes == 0 ? null : this.minutesToHoursMinutes(obj.unpaidVacationMinutes),
                      overTimeClass: obj.overTime == 0 ? 'bg-pink-500' : 'bg-teal-500',
                      plusMminusMinutesClass: obj.minutesWorkedVsContractMinutes >= 0 ? 'plus-indicator' : 'minus-indicator',
                      plusMinusMinutes: obj.minutesWorkedVsContractMinutes ? this.minutesToHoursMinutes(obj.minutesWorkedVsContractMinutes, true) : null,
                    };
                    return obj;
                }) 
            return;
        })
        .catch( (err) => {
            console.log("ERROR!", err);
        })
        .finally(() => {
            this.dataIsLoading = false;

            if( JSON.stringify(newDateObject) != this.$route.query.date){
              this.$router.replace({
                query: Object.assign({}, this.$route.query, {
                date: JSON.stringify(newDateObject)
                }),
              });
            }
        });
      }
  },
}
</script>


<style>
.calendar-font {
  font-size: 12px;
}
.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.h-full {
  height: 100%;
}

.plus-minus{
  position: absolute;
  right: 0;
  margin-left: 20px;
  padding: 0;
  top: 0;
}

.minus-indicator {
  color: #e71414;
  background-color: #f7f7f7;
}

.plus-indicator {
  color: #0eac00;
  background-color: #f7f7f7;
}

.bg-red-600 {
  background-color: #e53e3e;
}
.today {
  background-color: #d2e1ec;
}
.bg-teal-500 {
  background-color: #38b2ac;
}
.bg-pink-500 {
  background-color: #ed64a6;
}
.bg-indigo-500 {
  background-color: #667eea;
}

.vacation-festivity {
  background-color: #b10c95;
}

.vacation-sick {
  background-color: #667eea;
  color: white;
}
.vacation-hours {
  background-color: #d2e1ec;
}
.vacation-vacation {
  background-color: #fda5cf;
}

.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.z-10 {
  z-index: 10;
}
.overflow-hidden {
  overflow: hidden;
}
.text-gray-900 {
  color: #1a202c;
}
.vc-h-full {
  height: 100%;
}
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 40px;
  --day-height: 70px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: auto;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
  height: 80vh;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(4, 1fr) !important;
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.vc-weekday {
    display: none;
}

.vc-border {
  border-width: 1px;
}
.custom-calendar.vc-container .vc-day {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #ffb7b7;
}

.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px;
  text-align: left;
  /*min-height: var(--day-height);*/
  min-width: var(--day-width);
  background-color: #fff;
}
.vc-day {
  position: relative;
  min-height: var(--day-min-height);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.text-center {
  text-align: center;
}

.max-w-full {
  max-width: 100%;
}
.bg-blue-500 {
  background-color: #4299e1;
}

.text-xs {
  font-size: 0.75rem;
}
.text-white {
  color: #fff;
}
.p-1 {
  padding: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-0 {
  margin-top: 0;
}
.leading-tight {
  line-height: 1.25;
}
.rounded-sm {
  border-radius: 0.125rem;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1000;
  font-size: 1.3em;
}
.is-not-in-month {
    display: none;
}
</style>
