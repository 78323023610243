<template>
  <div  class="main-container" :class="{ 'blur-content': blurBackground }">
    <EmployeeHome />
  </div>

</template>

<script>
import { getMe, forceLogout } from "@/services/APIcalls.js";
import EmployeeHome from "./Employee/Home.vue"
//import Webpush from 'webpush-client'

export default {
  components: {
    EmployeeHome,
  },
  setup() {
    return {};
  },
  data() {
    return {
      showSidebar: window.innerWidth > 1099,
      avatarCapitalLetters: "",
      blurBackground: false,
    };
  },
  async mounted() {
    await getMe();
    this.me = JSON.parse(localStorage.getItem("me") || "{}");
    if (this.me.email == undefined || !this.me) forceLogout();
    /**
     * todo: notifications
 
    Webpush.create({
        serviceWorkerPath: '/js/webpush-sw.js', // Public path to the service worker
        serverKey: 'BHUe5RLrdIpbgyWH9bscr9zGbQWGSdY08-q_FMyIxC_lyVPmJZ_7nqAsTbZpdodWvLtD3htxB9nlx12OTeijXvI', // https://developers.google.com/web/fundamentals/push-notifications/web-push-protocol#application_server_keys
        subscribeUrl: 'https://admin.timetrack.local/webpush', // Optional - your application URL to store webpush subscriptions
    })
        .then(WebPushClient => {
          const pippo = WebPushClient.subscribe();
          console.log("LOLO",pippo);
          console.log("POPO",WebPushClient.getSubscription());

            // do stuff with WebPushClient
        })
    ;

   */
  },
  created() {
    window.addEventListener("resize", this.responsiveSidebar);
  },
  destroyed() {
    window.removeEventListener("resize", this.responsiveSidebar);
  },
  methods: {
    logOut: () => forceLogout(),
    responsiveSidebar() {
      this.showSidebar = window.innerWidth > 1099;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
  top: 60px;
  margin: auto;
  width: fit-content;
}

.child-container {
  padding-left: 250px;
  height: 100%;
  width: 100%;
}
.logo {
  max-width: 170px;
  height: auto;
  margin: 20px auto;
  display: block;
  filter: invert(19%) sepia(34%) saturate(3356%) hue-rotate(215deg)
    brightness(91%) contrast(101%);
}

.dropdown-divider {
  color: #e8e8e8;
}

.nav-link {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #52575c;
  & svg {
    color: #c0c0c0;
  }
}
.active {
  color: #5759d6;
  & svg {
    color: #5759d6;
  }
}

.blur-content {
  filter: blur(7px);
}

.avatar {
  background-color: var(--light-primary);
  color: white;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

@media (max-width: 1099px) {
  .child-container {
    padding-left: 0;
  }
  .content {
    top: 0;
    transform: translateY(0);
  }
}
</style>
