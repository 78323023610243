<template>
    <b-modal
        centered
        @hidden="onDismiss()"
        @ok="createNewTimeLog"
        id="create-timelog-modal"
        ok-title="Invia"
        cancel-title="Annulla"
    >
        <template>
            <div slot="modal-header">
                <span class="d-inline-flex modal-header-bar">
                    <h5><strong>Crea nuova timbratura</strong></h5>
                    <b-icon
                    style="cursor: pointer"
                    icon="x"
                    shift-v="-4"
                    scale="1.7"
                    @click="$bvModal.hide('create-timelog-modal')"
                    ></b-icon>
                </span>
            </div>
        </template>
        <div class="mt-3">
            <p class="text-danger">{{errorMsg}}</p>
            <p>Usa questo form per creare una timbratura per un dato giorno. Ogni timbratura inserita sarà revisionata da un amministratore.</p>
            <b-form-group
                label="Data"
                label-for="select-a-date"
                label-cols-sm="3"
                label-align-sm="right"
            >
                <b-form-datepicker
                    id="select-a-date"
                    :state="formDateError"
                    v-model="newTimeLogDate"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-2"
                    locale="it"
                    v-bind="labels"
                    :max="yesterday"
                    no-highlight-today
                    nav-button-variant="primary"
                ></b-form-datepicker>
            </b-form-group>

            <b-form-group
                label="Ora"
                label-for="start-hour"
                label-cols-sm="3"
                label-align-sm="right"
            >
                <b-form-timepicker
                    id="start-hour"
                    :state="formTimeError"
                    minutes-step=1
                    v-model="timeLogTime"
                    v-bind="labels"
                    locale="it"
                    class="mb-3">
                ></b-form-timepicker>
            </b-form-group>
            <b-form-group
                class="mt-2"
                label="Tipologia"
                v-slot="{ ariaDescribedby }"
            >
                <b-form-radio-group
                id="select-timeLog-Type"
                v-model="timeLogType"
                :options="timeLogTypes"
                :aria-describedby="ariaDescribedby"
                name="select-timeLog-Type"
                plain
                ></b-form-radio-group>
            </b-form-group>
        </div>
    <template #modal-footer>
      <b-button variant="primary" @click="createNewTimeLog()">
        OK
      </b-button>
      <b-button variant="secondary" @click="$bvModal.hide('create-timelog-modal')">
        Annulla
      </b-button>
      <!-- Button with custom close trigger value -->
    </template>
    </b-modal>
</template>

<script>
import { createNewTimeLog } from "@/services/APIcalls.js";

export default {
    data() {
    return {
        timeLogType: true,
        errorMsg: '',
        formDateError: null,
        formTimeError: null,
        timeLogTypes: [
        { text: 'Ingresso', value: true },
        { text: 'Uscita', value: false },
        ],
        employeesForAutocomplete: [],
        lastNameForAutoComplete: '',
        showCreateNewTimeLog: false,
        isLoading: false,
        newTimeLogDate: '',
        timeLogTime: null,
        today: new Date(),
        yesterday: new Date(Date.now() - 86400000),
        labels: {
        labelPrevDecade: "Decennio precedente",
        labelPrevYear: "Anno precedente",
        labelPrevMonth: "Mese precedente",
        labelCurrentMonth: "Mese attuale",
        labelNextMonth: "Prossimo mese",
        labelNextYear: "Prossimo anno",
        labelNextDecade: "Prossimo decennio",
        labelToday: "Oggi",
        labelNoDateSelected: "Nessuna data selezionata",
        labelCalendar: "Calendario",
        labelNav: "Navigazione del calendario",
        labelHelp: "Usa i tasti freccia per navigare fra le date",
        labelHours: 'Ore',
        labelMinutes: 'Minuti',
        labelSeconds: 'Secondi',
        labelIncrement: 'Aumenta',
        labelDecrement: 'Diminuisci',
        labelNoTimeSelected: 'Nessun orario selezionato',
        labelCloseButton: 'Chiudi'
        },
    };
    },
    methods: {
        onDismiss() {
            this.errorMsg = "";
            this.formDateError = null;
            this.formTimeError = null;
            this.newTimeLogDate = '';
            this.timeLogTime = null;
            return;
        },
        createNewTimeLog(){
            this.errorMsg = "";
            this.formDateError = null;
            this.formTimeError = null;

            if( !this.newTimeLogDate && !this.timeLogTime) {
                this.errorMsg = "Per continuare devi riempire tutti i campi.";
                this.formDateError = false;
                this.formTimeError = false;
                return;
            }

            if( !this.newTimeLogDate ) {
                this.errorMsg = "Inserire la data per proseguire.";
                this.formDateError = false;
                return;
            }
            if( !this.timeLogTime ){
                this.errorMsg = "Inserire l'orario per proseguire.";
                this.formTimeError = false;
                return;
            }

            this.isLoading = true;

            const newTimeLog =  new Date(this.newTimeLogDate);
            newTimeLog.setHours(...this.timeLogTime.split(':'));
            this.isLoading = true;

            createNewTimeLog({type: this.timeLogType, newTimeLog: newTimeLog.toISOString() })
                .then(() => {
                    this.$emit('refresh');
                    this.$bvModal.hide('create-timelog-modal');
                //todo : update history component
                })
                .catch((err) => {
                    this.errorMsg = "Errore imprevisto. Riprovare più tardi."
                    if (err.response.status === 406) {
                        this.errorMsg = err.response.data.msg;
                    }
                    console.error('Error ',err);
                })
                .finally(() => {
                    this.isLoading = false;
                })
        },
    }
}
</script>